export const reviews = [
  {
    name: "Priyansu Singh",
    username: "@priyansu",
    body: "Aurora AI has transformed my workflow. The intelligence and speed are unmatched!",
    img: "https://avatars.githubusercontent.com/u/115893309?v=4",
    live: "https://github.com/priyansusingh"
  },
  {
    name: "Om Sharma",
    username: "@om",
    body: "The insights from Aurora AI are incredible. It understands my needs almost perfectly.",
    img: "https://avatars.githubusercontent.com/u/116426515?s=100&v=4",
    live: "https://github.com/i-OmSharma"
  },
  {
    name: "Shivam Shaw",
    username: "@shivam",
    body: "Aurora AI has become an indispensable tool in my daily routine. Highly recommend it!",
    img: "https://avatars.githubusercontent.com/u/108209696?v=4",
    live: "https://github.com/mrshivamshaw"
  },
  {
    name: "Shubam Patil",
    username: "@subham",
    body: "I’ve used many AI tools, but Aurora AI stands out for its accuracy and ease of use.",
    img: "https://i.pravatar.cc/150?u=a04258114e29026708c",
    live: "https://x.com/shubhamsp1602"
  },
  {
    name: "Ayush Bhalotia",
    username: "@ayush",
    body: "Aurora AI helped me streamline my tasks and save so much time. A game changer!",
    img: "https://i.pravatar.cc/150?u=a042581f4e29026024d",
    live: "https://www.linkedin.com/in/ayushhbhalotia/"
  }
];
